import React, {Component} from "react";
import ActiveCaseList from "./ActiveCaseList";

export default class SupervisorComponent extends Component {

    render() {
        return (
            <ActiveCaseList/>
        )
    }
}
